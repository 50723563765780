import * as Sentry from "@sentry/nuxt";
import { CheckoutSuccessStatus } from "~/types/checkoutSuccess";

export default defineNuxtRouteMiddleware(() => {
  const { $pinia } = useNuxtApp();

  const config = useRuntimeConfig();
  const { registrationParams } = useRegistration();
  const { checkoutSuccess } = storeToRefs(useCheckoutStore($pinia));

  if (!registrationParams.value.email) {
    Sentry.captureMessage("canAccessFinalizeAccountPage: No E-Mail in registrationParams.", {
      extra: {
        registrationParams: registrationParams.value,
      },
    });
    return navigateTo(`${config.app.baseURL}/onboarding`, { external: true });
  }

  if (checkoutSuccess.value === CheckoutSuccessStatus.ACCOUNT_FINALIZED) {
    return navigateTo("/onboarding/success");
  }

  if (checkoutSuccess.value !== CheckoutSuccessStatus.PAID) {
    return navigateTo("/onboarding/checkout");
  }
});
